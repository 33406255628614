import React from 'react'
import { IoLogoInstagram } from "react-icons/io5";
import { AiOutlineTikTok } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { CiLocationOn } from "react-icons/ci";

function Footer() {
  return (
    <div className='footer'>

        <div className="footer__net">
        <h2 className="footer__net-title">Подписывайтесь на нас</h2>
        <a href="https://www.instagram.com/itmarket_osh/" target="_blank" className='footer__net-text'><IoLogoInstagram />Instagram</a>
        <a href="https://www.tiktok.com/@itmarket.osh?_t=8oviGoptljx&_r=1" target="_blank" className='footer__net-text'><AiOutlineTikTok />Tik Tok</a>
      </div>

      <p className='footer__text'>Nano 2024</p>

      <div className="footer__contacts">
        <h2 className="footer__contacts-title">Контакты</h2>
        <a href="https://wa.me/996779740794" target="_blank" className='footer__contacts-text'><BsTelephone />+996 779 74 07 94</a>
        <a href="https://maps.app.goo.gl/Hkx3vfkwX56xYpey9" target="_blank" className='footer__contacts-text'><CiLocationOn />Масалиева 44 ТЦ Корона 1-этаж, Osh, Kyrgyzstan</a>
      </div>

    </div>
    
  )
}

export default Footer

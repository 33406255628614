import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import book from './img/Home__img.png';
import { Link } from 'react-router-dom';

function Home() {

    const [rekom, setRekom] = useState([]);

    const sliceitem3 = rekom.slice(3, 4)

    const sliceitem1 = rekom.slice(1, 2)
    const sliceitem2 = rekom.slice(2, 3)


    useEffect(() => {
        axios(`https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUjFGYVR4a1VwVWxIOXFhMGs1V19lYkRwTDF0U1dKQlFqSndETzdiNjRaejRTd2hadkV5eHBLUUlYTFVPWEFXWHBjelFrWV9pNjhQX3NjbFhlZA3NvMzNn`)
            .then(({ data }) => setRekom(data.data))
    }, [])
    if (!rekom || rekom.length === 0) return <p>Загрузка данных.</p>


    return (
        <div className='home'>
            <div className="home__form">

                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >

                    <SwiperSlide>
                        <div className='home__card'>

                            {sliceitem1.map((item, idx) => {


                                return (
                                    <div className="home__card-img">

                                        <div className="home__card-text">
                                            <h2 className='home__card-name'>Acer</h2>
                                            <p className='home__card-ima'>Отличный ноутбук для учебы программирования и игр 💻</p>
                                            <p className='home__card-price'>💰Цена 23000 сом</p>
                                            <button className='products__form-btn'><Link className='products__form-btn-link' to={`/Podrobnee/${item.id}`}>Подробнее</Link></button>
                                        </div>

                                        <img className='home__card-photo' src={item.media_url} />
                                    </div>
                                )



                            })}

                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className='home__card'>

                            {sliceitem2.map((item, idx) => {


                                return (
                                    <div className="home__card-img">

                                        <div className="home__card-text">
                                            <h2 className='home__card-name'>Acer</h2>
                                            <p className='home__card-ima'>Отличный ноутбук для учебы программирования и игр 💻</p>
                                            <p className='home__card-price'>💰Цена 18500 сом</p>
                                            <button className='products__form-btn'><Link className='products__form-btn-link' to={`/Podrobnee/${item.id}`}>Подробнее</Link></button>
                                        </div>

                                        <img className='home__card-photo' src={item.media_url} />
                                    </div>
                                )



                            })}

                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className='home__card'>

                            {sliceitem3.map((item, idx) => {


                                return (
                                    <div className="home__card-img">

                                        <div className="home__card-text">
                                            <h2 className='home__card-name'>Asus</h2>
                                            <p className='home__card-ima'>Отличный ноутбук для учебы программирования и игр 💻</p>
                                            <p className='home__card-price'>Цена 34500 сом</p>
                                            <button className='products__form-btn'><Link className='products__form-btn-link' to={`/Podrobnee/${item.id}`}>Подробнее</Link></button>
                                        </div>

                                        <img className='home__card-photo' src={item.media_url} />
                                    </div>
                                )



                            })}

                        </div>
                    </SwiperSlide>

                </Swiper>




            </div>
        </div>
    )
}

export default Home

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BsMenuButton } from 'react-icons/bs';
import { Link } from 'react-router-dom';
function Two() {

    const [tovartwo, setTovartwo] = useState([]);

    useEffect(() => {
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUmRfNFJNMGZACbWgxc2ZAYOUh4dlZAtSGtidkFGYjBKRy0tZAXB2SDZAhQXdEcFJHUGJpcnAydF9OVVFqOVNERVU3UU9TUDI0Q0F1MzZArSWM0ZAXcxZAjdzUzNn")
            .then(({ data }) => setTovartwo(data.data))
    }, [])





    if (!tovartwo || tovartwo.length === 0) return <p>Загрузка данных.</p>
    const images = tovartwo.filter(element => element.media_type === 'CAROUSEL_ALBUM');

    return (
        <div className='products'>


            {images.map((item, idx) => {
                return (
                    <div className="products__form">
                        <div key={item.id} className="products__form-card">
                            <img className='products__form-photo' src={item.media_url} alt={item.caption} />
                            <button className='products__form-btn'>
                                <Link className='products__form-btn-link' to={`/Podrobnee/${item.id}`}>Подробнее</Link>
                            </button>
                        </div>
                    </div>
                )
            })}


        </div>
    )
}

export default Two

import React, { useState } from 'react';
import Home from './Home/Home'
import Rek from './Rek/Rek';

function Main() {

  return (
    <div className='main'>
        <Home/>
        <Rek/>
    </div>
  )
}

export default Main

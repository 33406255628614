import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import sass from './App.scss'
import Products from './components/Main/Products/Products';
import Two from './components/Main/Prod/Two';
import Three from './components/Main/Prod/Three';
import Four from './components/Main/Prod/Four';
import Five from './components/Main/Prod/Five';
import Six from './components/Main/Prod/Six';
import Seven from './components/Main/Prod/Seven';
import Eight from './components/Main/Prod/Eight';
import Nine from './components/Main/Prod/Nine';
import Ten from './components/Main/Prod/Ten';
import Elenev from './components/Main/Prod/Eleven';
import Twelve from './components/Main/Prod/Twelve';
import Thirteen from './components/Main/Prod/Thirteen';
import Podrobnee from './components/Main/Podrobnee/Podrobnee';
import Prod from './components/Main/Prod/Prod';
import Cart from './components/Main/Cart/Cart';
import Contact from './components/Main/Contact/Contact';
import Printer from './components/Main/Products/Printer';
import Pk from './components/Main/Products/Pk';
import Nout from './components/Main/Products/Nout';
import Kl from './components/Main/Products/Kl';
import ModelAcer from './components/Main/Products/models/ModelAcer';
import ModelLenovo from './components/Main/Products/models/ModelLenovo';
import ModelHp from './components/Main/Products/models/ModelHp';
import ModelDell from './components/Main/Products/models/ModelDell';
import ModelMacBook from './components/Main/Products/models/ModelMacBook';
import ModelAsus from './components/Main/Products/models/ModelAsus';
import ModelSamsung from './components/Main/Products/models/ModelSamsung';
import ModelMsi from './components/Main/Products/models/ModelMsi';
import Avai from './components/Main/Products/Avai';
import Fourteen from './components/Main/Prod/Fourteen';


function App({ tovar, setTovar, tovartwo, setTovartwo, tovarthree, setTovarthree, tovarfour, setTovarfour, tovarfive, setTovarfive, tovarsix, setTovarsix, tovarseven, setTovarseven, tovareight, setTovareight, tovarnine, setTovarnine, tovarten, setTovarten, tovareleven, setTovareleven, tovartwelve, setTovartwelve, tovarthirteen, setTovarthirteen, pod, tovarFourteen, setTovarFourteen, }) {
  const [cart, setCart] = useState([]);

  const addToCart = (pod) => {
    setCart(prevCart => {
      const productIndex = prevCart.findIndex(item => item.id === pod.id);

      if (productIndex >= 0) {

        const updatedCart = prevCart.map(item =>
          item.id === pod.id ? { ...item, quantity: item.quantity + 1 } : item
        );
        return updatedCart;
      } else {

        return [...prevCart, { ...pod, quantity: 1 }];
      }
    });
  };


  return (
    <div className="App">
      <div className="container">

        <BrowserRouter>
          <Header />

          <Routes>

            <Route path='/' element={<Main />} />
            <Route path='Products/*' element={<Products tovar={tovar} />}>
              <Route path='Printer' element={<Printer/>} />
              <Route path='Avai' element={<Avai/>} />
              <Route path='Pk' element={<Pk/>} />
              <Route path='Nout' element={<Nout/>} />
              <Route path='Kl' element={<Kl/>} />
              <Route path='Acer' element={<ModelAcer/>} />
              <Route path='Lenovo' element={<ModelLenovo/>} />
              <Route path='Hp' element={<ModelHp/>} />
              <Route path='Dell' element={<ModelDell/>} />
              <Route path='MacBook' element={<ModelMacBook/>} />
              <Route path='Asus' element={<ModelAsus/>} />
              <Route path='Samsung' element={<ModelSamsung/>} />
              <Route path='Msi' element={<ModelMsi/>} />
              <Route path='Prod' element={<Prod />} />
              <Route path='twoProducts' element={<Two />} />
              <Route path='threeProducts' element={<Three tovarthree={tovarthree} setTovarthree={setTovarthree} />} />
              <Route path='threeProducts' element={<Three tovarthree={tovarthree} setTovarthree={setTovarthree} />} />
              <Route path='fourProducts' element={<Four tovarfour={tovarfour} setTovarfour={setTovarfour} />} />
              <Route path='fiveProducts' element={<Five tovarfive={tovarfive} setTovarfive={setTovarfive} />} />
              <Route path='sixProducts' element={<Six tovarsix={tovarsix} setTovarsix={setTovarsix} />} />
              <Route path='sevenProducts' element={<Seven tovarseven={tovarseven} setTovarseven={setTovarseven} />} />
              <Route path='eightProducts' element={<Eight tovareight={tovareight} setTovareight={setTovareight} />} />
              <Route path='nineProducts' element={<Nine tovarnine={tovarnine} setTovarnine={setTovarnine} />} />
              <Route path='tenProducts' element={<Ten tovarten={tovarten} setTovarten={setTovarten} />} />
              <Route path='elevenProducts' element={<Elenev tovareleven={tovareleven} setTovareleven={setTovareleven} />} />
              <Route path='twelveProducts' element={<Twelve tovartwelve={tovartwelve} setTovartwelve={setTovartwelve} />} />
              <Route path='thirteenProducts' element={<Thirteen tovarthirteen={tovarthirteen} setTovarthirteen={setTovarthirteen} />} />
              <Route path='fourteenProducts' element={<Fourteen tovarFourteen={tovarFourteen} setTovarFourteen={setTovarFourteen} />} />
            </Route>
            <Route path='/Podrobnee/:id' element={<Podrobnee addToCart={addToCart} />} />
            <Route path='/Cart' element={<Cart cart={cart} setCart={setCart} />} />
            <Route path='/Contact/:id' element={<Contact />} />

          </Routes>

          <Footer />
        </BrowserRouter>

      </div>
    </div>
  );
}

export default App

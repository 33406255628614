import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function Prod() {

    const [tovar, setTovar] = useState([]);


    useEffect(() => {
        axios(`https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD`)
            .then(({ data }) => setTovar(data.data))
    }, [])

    if (!tovar || tovar.length === 0) return <p style={{textAlign: 'center'}}>Загрузка данных.</p>
    const images = tovar.filter(element => element.media_type === 'CAROUSEL_ALBUM');

    return (
        <div className='products'>

{images.map((item) => (
                <div key={item.id} className="products__form">
                    <div className="products__form-card">
                        <img className='products__form-photo' src={item.media_url} alt={item.caption} />
                        <button className='products__form-btn'>
                            <Link className='products__form-btn-link' to={`/Podrobnee/${item.id}`}>Подробнее</Link>
                        </button>
                    </div>
                </div>
            ))}


        </div>
    )
}

export default Prod
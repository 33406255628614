import React, { useState, useEffect } from 'react';
import { CiSearch } from "react-icons/ci";
import { Link } from 'react-router-dom';

function Header() {

  return (
    <div className='header'>
      <div className="header__welcome">
        <h3 className='header__welcome-title'>Добро пожаловать в наш онлайн магазин!</h3>
      </div>

      <div className="header__home">
        <Link className='header__home-title' to='/'><h1 className='header__home-title'>IT Market</h1></Link>
      </div>

      <hr />

      <nav className="header__nav">
        <ul className='header__nav-menu'>
          <li><Link to='/' className='header__menu-title'>Главная</Link></li>
          <li><Link to='Products/Prod' className='header__menu-title'>Продукты</Link></li>
          <li><Link to='/Cart' className='header__menu-title'>Моя корзина</Link></li>
        </ul>
      </nav>
    </div>
  );
}

export default Header;









import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

function Cart({ cart, setCart }) {

    const [podrobnee, setPodrobnee] = useState([])
    const [rek, setRek] = useState([]);
    const [tovartwo, setTovartwo] = useState([]);
    const [tovarthree, setTovarthree] = useState([]);
    const [tovarfour, setTovarfour] = useState([]);
    const [tovarfive, setTovarfive] = useState([]);
    const [tovarsix, setTovarsix] = useState([]);
    const [tovarseven, setTovarseven] = useState([]);
    const [tovareight, setTovareight] = useState([]);
    const [tovarnine, setTovarnine] = useState([]);
    const [tovarten, setTovarten] = useState([]);
    const [tovareleven, setTovareleven] = useState([]);
    const [tovartwelve, setTovartwelve] = useState([]);
    const [tovarthirteen, setTovarthirteen] = useState([]);
    const sliceitem = rek.slice(0, 9)
    const [tovarFourteen, setTovarFourteen] = useState([]);

    const { id } = useParams();

    useEffect(() => {
        axios(`https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUmR6Y1BjVXZAHbjI3WnBuRTBVMkV1TlpxZAVpxV0x3eHRCSURuekN4UFQ2SHRLcDItU0xvYkRMbEppMGxSWGF1VHl4VTJaRXpNcnBHVGNISnRFUVVzeS1n`)
            .then(({ data }) => setTovarFourteen(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUmloVm91ZAmNWdzdCaDVqSmNJd3FoNnNrdUxpb3BUcHpnSzJOVzRRblZAnaS1EeVpmMFd0dE5GMkt1S0p4VVN1dF9JN05VUTJfbnZA0Q01Ea0xScmNFYXd3")
            .then(({ data }) => setTovarthirteen(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUkxBVzBwSldaaXcwbzFjZA1l3eDdWaDRTZA2owYm1jenJMQ24tbVBQMDhZAOHdxQ0gwV0tib0pram9JQjhqSHI0b3lwaTJsTk5UcV9BS1R4TTRBWDdVNHpn")
            .then(({ data }) => setTovartwelve(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUnpiMGRBLUVNSjhZASU9vVUE4cUpNNTZAYdG9lMFRYQ0pBSzkyN2V5ZAWtaeHhWT2MweTIzbjVEUGttTFVXY3pUMk15cUhpX2Nlakx1azN3TkdsZADhodEpB")
            .then(({ data }) => setTovareleven(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUmlCZAUQ4aWpVbkdtTTdoNWtCVVVEcV9hUW0wcFlla3k5SzhsalJja05rRlA1TWpJSVl1aUlUQVZANYkc0MUhKSW5JTWxjVFdsekxlTzVNdDhIY3dPT1BR")
            .then(({ data }) => setTovarten(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUnYySTY1U1J5RUQ5ZAG1OT3hoLW5Hd0liSC1fOER1VjZAUaVFuOXVSdmd2azBsS2dCckFRWV9yVzVZANWJENVp3YXhLVGxZAaDhCS2xkbXl2MTRjVHNCeWV3")
            .then(({ data }) => setTovarnine(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUmNUdHIydE00OUpFMUZAaQWdzRDZANRFhoMzhlNWtfVGx4dFphWmVmQTdyY3p2S3Jtdm91N3R5bVBqa1k4TmtfbEVjT1N1Xy0yWTlFbDRHRHlPdkFzNmlB")
            .then(({ data }) => setTovareight(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUkdjQ1JVRE16ZAG5kVG91anV4ajlyb2U1X0NmOWJLQ3RYaHg3NUZA2VWZACbEJ6MnZAhZAkw1Um81V3ZATX1NINnZAjcFVIV0F6WkZA5YkhTQ1BnOWdqY2dOa0x3")
            .then(({ data }) => setTovarseven(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUjhJZAWx2OFpDYWpwZA21Da2pONEpSOGItUUttQ1FFdkZAHcjBkMWFzV2x4YjVrSDdCWkVEdl9hcDJoZAjJqZAmFFQm9lYXl2UHEyMk9uNmlBb2dsUnBBQnF3")
            .then(({ data }) => setTovarsix(data.data))
        axios(`https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUm9xQ0laV3ozRWhxWUthU003bVR1djFuUXB3YTVReTZArWDNoRjFqYWtQR2M4QWtQUmFkUkVlMVF5VENoa2tiaUFkS09pYkNmeWpnRUZAoMTZAYMlRUVXVB`)
            .then(({ data }) => setTovarfive(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUjVvNEpzZAHBmX21Wak1vWHp3RUNqM3g1OGxnbDczN05ock4zRlZAjQUl4ck5DZADN6ZAFFNTnUxNzlrXzNmdFJERVlDUHBLTXBtQ0VDS0RfZAUJ4bEQ0NDBB")
            .then(({ data }) => setTovarfour(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUjFGYVR4a1VwVWxIOXFhMGs1V19lYkRwTDF0U1dKQlFqSndETzdiNjRaejRTd2hadkV5eHBLUUlYTFVPWEFXWHBjelFrWV9pNjhQX3NjbFhlZA3NvMzNn")
            .then(({ data }) => setTovarthree(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUmRfNFJNMGZACbWgxc2ZAYOUh4dlZAtSGtidkFGYjBKRy0tZAXB2SDZAhQXdEcFJHUGJpcnAydF9OVVFqOVNERVU3UU9TUDI0Q0F1MzZArSWM0ZAXcxZAjdzUzNn")
            .then(({ data }) => setTovartwo(data.data))
        axios(`https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD`)
            .then(({ data }) => setPodrobnee(data.data))
        axios(`https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUm9xQ0laV3ozRWhxWUthU003bVR1djFuUXB3YTVReTZArWDNoRjFqYWtQR2M4QWtQUmFkUkVlMVF5VENoa2tiaUFkS09pYkNmeWpnRUZAoMTZAYMlRUVXVB`)
            .then(({ data }) => setRek(data.data))
    }, [])


    const item = podrobnee.find(element => element.id === id);
    const item1 = rek.find(element => element.id === id);
    const item2 = tovartwo.find(element => element.id === id);
    const item3 = tovarthree.find(element => element.id === id);
    const item4 = tovarfour.find(element => element.id === id);
    const item5 = tovarfive.find(element => element.id === id);
    const item6 = tovarsix.find(element => element.id === id);
    const item7 = tovarseven.find(element => element.id === id);
    const item8 = tovareight.find(element => element.id === id);
    const item9 = tovarnine.find(element => element.id === id);
    const item10 = tovarten.find(element => element.id === id);
    const item11 = tovareleven.find(element => element.id === id);
    const item12 = tovartwelve.find(element => element.id === id);
    const item13 = tovarthirteen.find(element => element.id === id);
    const item14 = tovarFourteen.find(element => element.id === id);

    const [pod, setPod] = useState([
        item,
        item1,
        item2,
        item3,
        item4,
        item5,
        item6,
        item7,
        item8,
        item9,
        item10,
        item11,
        item12,
        item13,
        item14,
    ]);

    const deleteProduct = (id) => {
        setCart(cart.filter(item => item.id !== id));
    };


    if (!cart || cart.length === 0) return <p style={{textAlign: "center"}}>Корзина пуста</p>
    return (
        <div className='cart'>
            <h1 className='cart__title'>Корзина</h1>
            <hr />


            <div className="cart__form">

                {cart.map((item) => (
                    <div className="cart__form-block" key={item.id}>
                        <div className="cart__form-block-corm">
                            <img className='cart__form-block-img' src={item.media_url} alt={item.caption} />
                        </div>

                        <div className="cart__form-block-cormi">
                            <Link to={`/Contact/${item.id}`} className='cart__form-block-btnlink'>
                                <button className='cart__form-block-btn'>Оформить заказ</button>
                            </Link>
                            <button className='cart__form-block-number' onClick={() => { deleteProduct(item.id) }}>Удалить</button>
                        </div>
                    </div>
                ))}


            </div>

        </div>
    )
}

export default Cart

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Outlet, Link, useLocation } from 'react-router-dom'
import Prod from '../Prod/Prod';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';


function Products() {

    const [podrobnee, setPodrobnee] = useState([])
    const [rek, setRek] = useState([]);
    const [tovartwo, setTovartwo] = useState([]);
    const [tovarthree, setTovarthree] = useState([]);
    const [tovarfour, setTovarfour] = useState([]);
    const [tovarfive, setTovarfive] = useState([]);
    const [tovarsix, setTovarsix] = useState([]);
    const [tovarseven, setTovarseven] = useState([]);
    const [tovareight, setTovareight] = useState([]);
    const [tovarnine, setTovarnine] = useState([]);
    const [tovarten, setTovarten] = useState([]);
    const [tovareleven, setTovareleven] = useState([]);
    const [tovartwelve, setTovartwelve] = useState([]);
    const [tovarthirteen, setTovarthirteen] = useState([]);
    const [tovarFourteen, setTovarFourteen] = useState([]);

    const { id } = useParams();

    useEffect(() => {
        axios(`https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUmR6Y1BjVXZAHbjI3WnBuRTBVMkV1TlpxZAVpxV0x3eHRCSURuekN4UFQ2SHRLcDItU0xvYkRMbEppMGxSWGF1VHl4VTJaRXpNcnBHVGNISnRFUVVzeS1n`)
            .then(({ data }) => setTovarFourteen(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUmloVm91ZAmNWdzdCaDVqSmNJd3FoNnNrdUxpb3BUcHpnSzJOVzRRblZAnaS1EeVpmMFd0dE5GMkt1S0p4VVN1dF9JN05VUTJfbnZA0Q01Ea0xScmNFYXd3")
            .then(({ data }) => setTovarthirteen(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUkxBVzBwSldaaXcwbzFjZA1l3eDdWaDRTZA2owYm1jenJMQ24tbVBQMDhZAOHdxQ0gwV0tib0pram9JQjhqSHI0b3lwaTJsTk5UcV9BS1R4TTRBWDdVNHpn")
            .then(({ data }) => setTovartwelve(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUnpiMGRBLUVNSjhZASU9vVUE4cUpNNTZAYdG9lMFRYQ0pBSzkyN2V5ZAWtaeHhWT2MweTIzbjVEUGttTFVXY3pUMk15cUhpX2Nlakx1azN3TkdsZADhodEpB")
            .then(({ data }) => setTovareleven(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUmlCZAUQ4aWpVbkdtTTdoNWtCVVVEcV9hUW0wcFlla3k5SzhsalJja05rRlA1TWpJSVl1aUlUQVZANYkc0MUhKSW5JTWxjVFdsekxlTzVNdDhIY3dPT1BR")
            .then(({ data }) => setTovarten(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUnYySTY1U1J5RUQ5ZAG1OT3hoLW5Hd0liSC1fOER1VjZAUaVFuOXVSdmd2azBsS2dCckFRWV9yVzVZANWJENVp3YXhLVGxZAaDhCS2xkbXl2MTRjVHNCeWV3")
            .then(({ data }) => setTovarnine(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUmNUdHIydE00OUpFMUZAaQWdzRDZANRFhoMzhlNWtfVGx4dFphWmVmQTdyY3p2S3Jtdm91N3R5bVBqa1k4TmtfbEVjT1N1Xy0yWTlFbDRHRHlPdkFzNmlB")
            .then(({ data }) => setTovareight(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUkdjQ1JVRE16ZAG5kVG91anV4ajlyb2U1X0NmOWJLQ3RYaHg3NUZA2VWZACbEJ6MnZAhZAkw1Um81V3ZATX1NINnZAjcFVIV0F6WkZA5YkhTQ1BnOWdqY2dOa0x3")
            .then(({ data }) => setTovarseven(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUjhJZAWx2OFpDYWpwZA21Da2pONEpSOGItUUttQ1FFdkZAHcjBkMWFzV2x4YjVrSDdCWkVEdl9hcDJoZAjJqZAmFFQm9lYXl2UHEyMk9uNmlBb2dsUnBBQnF3")
            .then(({ data }) => setTovarsix(data.data))
        axios(`https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUm9xQ0laV3ozRWhxWUthU003bVR1djFuUXB3YTVReTZArWDNoRjFqYWtQR2M4QWtQUmFkUkVlMVF5VENoa2tiaUFkS09pYkNmeWpnRUZAoMTZAYMlRUVXVB`)
            .then(({ data }) => setTovarfive(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUjVvNEpzZAHBmX21Wak1vWHp3RUNqM3g1OGxnbDczN05ock4zRlZAjQUl4ck5DZADN6ZAFFNTnUxNzlrXzNmdFJERVlDUHBLTXBtQ0VDS0RfZAUJ4bEQ0NDBB")
            .then(({ data }) => setTovarfour(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUjFGYVR4a1VwVWxIOXFhMGs1V19lYkRwTDF0U1dKQlFqSndETzdiNjRaejRTd2hadkV5eHBLUUlYTFVPWEFXWHBjelFrWV9pNjhQX3NjbFhlZA3NvMzNn")
            .then(({ data }) => setTovarthree(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUmRfNFJNMGZACbWgxc2ZAYOUh4dlZAtSGtidkFGYjBKRy0tZAXB2SDZAhQXdEcFJHUGJpcnAydF9OVVFqOVNERVU3UU9TUDI0Q0F1MzZArSWM0ZAXcxZAjdzUzNn")
            .then(({ data }) => setTovartwo(data.data))
        axios(`https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD`)
            .then(({ data }) => setPodrobnee(data.data))
        axios(`https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUm9xQ0laV3ozRWhxWUthU003bVR1djFuUXB3YTVReTZArWDNoRjFqYWtQR2M4QWtQUmFkUkVlMVF5VENoa2tiaUFkS09pYkNmeWpnRUZAoMTZAYMlRUVXVB`)
            .then(({ data }) => setRek(data.data))
    }, [])


    const item = podrobnee.find(element => element.id === id);
    const item1 = rek.find(element => element.id === id);
    const item2 = tovartwo.find(element => element.id === id);
    const item3 = tovarthree.find(element => element.id === id);
    const item4 = tovarfour.find(element => element.id === id);
    const item5 = tovarfive.find(element => element.id === id);
    const item6 = tovarsix.find(element => element.id === id);
    const item7 = tovarseven.find(element => element.id === id);
    const item8 = tovareight.find(element => element.id === id);
    const item9 = tovarnine.find(element => element.id === id);
    const item10 = tovarten.find(element => element.id === id);
    const item11 = tovareleven.find(element => element.id === id);
    const item12 = tovartwelve.find(element => element.id === id);
    const item13 = tovarthirteen.find(element => element.id === id);
    const item14 = tovarFourteen.find(element => element.id === id);


    const [allItems, setAllItems] = useState([
        podrobnee,
        rek,
        tovartwo,
        tovarthree,
        tovarfour,
        tovarfive,
        tovarsix,
        tovarseven,
        tovareight,
        tovarnine,
        tovarten,
        tovareleven,
        tovartwelve,
        tovarthirteen,
        tovarFourteen,
    ]);
    

    return (
        <div className='products'>
            <div className="products__nav">
                <li className="products__nav-li"><Link className="products__nav-li-link" to='Prod'>Все</Link></li>
                <li className="products__nav-li"><Link className="products__nav-li-link" to='Nout'>Ноутбуки</Link></li>
                <li className="products__nav-li"><Link className="products__nav-li-link" to='Pk'>Компьютеры</Link></li>
                <li className="products__nav-li"><Link className="products__nav-li-link" to='Kl'>Клавиатуры</Link></li>
                <li className="products__nav-li"><Link className="products__nav-li-link" to='Printer'>Принтеры</Link></li>
                <li className="products__nav-li"><Link className="products__nav-li-link" to='Avai'>В наличии</Link></li>
            </div>

            <div className="products__navmodel">
                <li className="products__nav-li"><Link className="products__nav-li-link" to='Acer'>Acer</Link></li>
                <li className="products__nav-li"><Link className="products__nav-li-link" to='Lenovo'>Lenovo</Link></li>
                <li className="products__nav-li"><Link className="products__nav-li-link" to='Hp'>Hp</Link></li>
                <li className="products__nav-li"><Link className="products__nav-li-link" to='Dell'>Dell</Link></li>
                <li className="products__nav-li"><Link className="products__nav-li-link" to='MacBook'>MacBook</Link></li>
                <li className="products__nav-li"><Link className="products__nav-li-link" to='Asus'>Asus</Link></li>
                <li className="products__nav-li"><Link className="products__nav-li-link" to='Msi'>Msi</Link></li>
                <li className="products__nav-li"><Link className="products__nav-li-link" to='Samsung'>Samsung</Link></li>
            </div>

            <Outlet />

            <Swiper
                navigation={true}
                modules={[Navigation]}
                slidesPerView={3}
                spaceBetween={400}
                className="mySwiper">
                <div className="products__sld">

                    <SwiperSlide className="swiper-slide-sld">
                        <Link className='products__sld-formbtn' to='Prod'>
                            <button className='products__sld-btn'>1</button>
                        </Link>
                    </SwiperSlide>

                    <SwiperSlide className="swiper-slide-sld">
                        <Link className='products__sld-formbtn' to='twoProducts'>
                            <button className='products__sld-btn'>2</button>
                        </Link>
                    </SwiperSlide>

                    <SwiperSlide className="swiper-slide-sld">
                        <Link className='products__sld-formbtn' to='threeProducts'>
                            <button className='products__sld-btn'>3</button>
                        </Link>
                    </SwiperSlide>

                    <SwiperSlide className="swiper-slide-sld">
                        <Link className='products__sld-formbtn' to='fourProducts'>
                            <button className='products__sld-btn'>4</button>
                        </Link>
                    </SwiperSlide>

                    <SwiperSlide className="swiper-slide-sld">
                        <Link className='products__sld-formbtn' to='fiveProducts'>
                            <button className='products__sld-btn'>5</button>
                        </Link>
                    </SwiperSlide>

                    <SwiperSlide className="swiper-slide-sld">
                        <Link className='products__sld-formbtn' to='sixProducts'>
                            <button className='products__sld-btn'>6</button>
                        </Link>
                    </SwiperSlide>

                    <SwiperSlide className="swiper-slide-sld">
                        <Link className='products__sld-formbtn' to='sevenProducts'>
                            <button className='products__sld-btn'>7</button>
                        </Link>
                    </SwiperSlide>

                    <SwiperSlide className="swiper-slide-sld">
                        <Link className='products__sld-formbtn' to='eightProducts'>
                            <button className='products__sld-btn'>8</button>
                        </Link>
                    </SwiperSlide>

                    <SwiperSlide className="swiper-slide-sld">
                        <Link className='products__sld-formbtn' to='nineProducts'>
                            <button className='products__sld-btn'>9</button>
                        </Link>
                    </SwiperSlide>

                    <SwiperSlide className="swiper-slide-sld">
                        <Link className='products__sld-formbtn' to='tenProducts'>
                            <button className='products__sld-btn'>10</button>
                        </Link>
                    </SwiperSlide>

                    <SwiperSlide className="swiper-slide-sld">
                        <Link className='products__sld-formbtn' to='elevenProducts'>
                            <button className='products__sld-btn'>11</button>
                        </Link>
                    </SwiperSlide>

                    <SwiperSlide className="swiper-slide-sld">
                        <Link className='products__sld-formbtn' to='twelveProducts'>
                            <button className='products__sld-btn'>12</button>
                        </Link>
                    </SwiperSlide>

                    <SwiperSlide className="swiper-slide-sld">
                        <Link className='products__sld-formbtn' to='thirteenProducts'>
                            <button className='products__sld-btn'>13</button>
                        </Link>
                    </SwiperSlide>

                    <SwiperSlide className="swiper-slide-sld">
                        <Link className='products__sld-formbtn' to='fourteenProducts'>
                            <button className='products__sld-btn'>14</button>
                        </Link>
                    </SwiperSlide>

                </div>
            </Swiper>

        </div >
    )
}

export default Products
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Outlet, Link, useLocation } from 'react-router-dom'

const ModelMacBook = () => {

    const [podrobnee, setPodrobnee] = useState([])
    const [rek, setRek] = useState([]);
    const [tovartwo, setTovartwo] = useState([]);
    const [tovarthree, setTovarthree] = useState([]);
    const [tovarfour, setTovarfour] = useState([]);
    const [tovarfive, setTovarfive] = useState([]);
    const [tovarsix, setTovarsix] = useState([]);
    const [tovarseven, setTovarseven] = useState([]);
    const [tovareight, setTovareight] = useState([]);
    const [tovarnine, setTovarnine] = useState([]);
    const [tovarten, setTovarten] = useState([]);
    const [tovareleven, setTovareleven] = useState([]);
    const [tovartwelve, setTovartwelve] = useState([]);
    const [tovarthirteen, setTovarthirteen] = useState([]);
    const [tovarFourteen, setTovarFourteen] = useState([]);

    useEffect(() => {
        axios(`https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUmR6Y1BjVXZAHbjI3WnBuRTBVMkV1TlpxZAVpxV0x3eHRCSURuekN4UFQ2SHRLcDItU0xvYkRMbEppMGxSWGF1VHl4VTJaRXpNcnBHVGNISnRFUVVzeS1n`)
            .then(({ data }) => setTovarFourteen(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUmloVm91ZAmNWdzdCaDVqSmNJd3FoNnNrdUxpb3BUcHpnSzJOVzRRblZAnaS1EeVpmMFd0dE5GMkt1S0p4VVN1dF9JN05VUTJfbnZA0Q01Ea0xScmNFYXd3")
            .then(({ data }) => setTovarthirteen(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUkxBVzBwSldaaXcwbzFjZA1l3eDdWaDRTZA2owYm1jenJMQ24tbVBQMDhZAOHdxQ0gwV0tib0pram9JQjhqSHI0b3lwaTJsTk5UcV9BS1R4TTRBWDdVNHpn")
            .then(({ data }) => setTovartwelve(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUnpiMGRBLUVNSjhZASU9vVUE4cUpNNTZAYdG9lMFRYQ0pBSzkyN2V5ZAWtaeHhWT2MweTIzbjVEUGttTFVXY3pUMk15cUhpX2Nlakx1azN3TkdsZADhodEpB")
            .then(({ data }) => setTovareleven(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUmlCZAUQ4aWpVbkdtTTdoNWtCVVVEcV9hUW0wcFlla3k5SzhsalJja05rRlA1TWpJSVl1aUlUQVZANYkc0MUhKSW5JTWxjVFdsekxlTzVNdDhIY3dPT1BR")
            .then(({ data }) => setTovarten(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUnYySTY1U1J5RUQ5ZAG1OT3hoLW5Hd0liSC1fOER1VjZAUaVFuOXVSdmd2azBsS2dCckFRWV9yVzVZANWJENVp3YXhLVGxZAaDhCS2xkbXl2MTRjVHNCeWV3")
            .then(({ data }) => setTovarnine(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUmNUdHIydE00OUpFMUZAaQWdzRDZANRFhoMzhlNWtfVGx4dFphWmVmQTdyY3p2S3Jtdm91N3R5bVBqa1k4TmtfbEVjT1N1Xy0yWTlFbDRHRHlPdkFzNmlB")
            .then(({ data }) => setTovareight(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUkdjQ1JVRE16ZAG5kVG91anV4ajlyb2U1X0NmOWJLQ3RYaHg3NUZA2VWZACbEJ6MnZAhZAkw1Um81V3ZATX1NINnZAjcFVIV0F6WkZA5YkhTQ1BnOWdqY2dOa0x3")
            .then(({ data }) => setTovarseven(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUjhJZAWx2OFpDYWpwZA21Da2pONEpSOGItUUttQ1FFdkZAHcjBkMWFzV2x4YjVrSDdCWkVEdl9hcDJoZAjJqZAmFFQm9lYXl2UHEyMk9uNmlBb2dsUnBBQnF3")
            .then(({ data }) => setTovarsix(data.data))
        axios(`https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUm9xQ0laV3ozRWhxWUthU003bVR1djFuUXB3YTVReTZArWDNoRjFqYWtQR2M4QWtQUmFkUkVlMVF5VENoa2tiaUFkS09pYkNmeWpnRUZAoMTZAYMlRUVXVB`)
            .then(({ data }) => setTovarfive(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUjVvNEpzZAHBmX21Wak1vWHp3RUNqM3g1OGxnbDczN05ock4zRlZAjQUl4ck5DZADN6ZAFFNTnUxNzlrXzNmdFJERVlDUHBLTXBtQ0VDS0RfZAUJ4bEQ0NDBB")
            .then(({ data }) => setTovarfour(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUjFGYVR4a1VwVWxIOXFhMGs1V19lYkRwTDF0U1dKQlFqSndETzdiNjRaejRTd2hadkV5eHBLUUlYTFVPWEFXWHBjelFrWV9pNjhQX3NjbFhlZA3NvMzNn")
            .then(({ data }) => setTovarthree(data.data))
        axios("https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUmRfNFJNMGZACbWgxc2ZAYOUh4dlZAtSGtidkFGYjBKRy0tZAXB2SDZAhQXdEcFJHUGJpcnAydF9OVVFqOVNERVU3UU9TUDI0Q0F1MzZArSWM0ZAXcxZAjdzUzNn")
            .then(({ data }) => setTovartwo(data.data))
        axios(`https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD`)
            .then(({ data }) => setPodrobnee(data.data))
        axios(`https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUm9xQ0laV3ozRWhxWUthU003bVR1djFuUXB3YTVReTZArWDNoRjFqYWtQR2M4QWtQUmFkUkVlMVF5VENoa2tiaUFkS09pYkNmeWpnRUZAoMTZAYMlRUVXVB`)
            .then(({ data }) => setRek(data.data))
    }, [])

    const [allItem, setAllItem] = useState([]);

    useEffect(() => {
        // Проверка на наличие данных во всех массивах
        if (
            podrobnee.length > 0 || rek.length > 0 || tovartwo.length > 0 ||
            tovarthree.length > 0 || tovarfour.length > 0 || tovarfive.length > 0 ||
            tovarsix.length > 0 || tovarseven.length > 0 || tovareight.length > 0 ||
            tovarnine.length > 0 || tovarten.length > 0 || tovareleven.length > 0 ||
            tovartwelve.length > 0 || tovarthirteen.length > 0 || tovarFourteen.length > 0
        ) {
            setAllItem([
                ...podrobnee,
                ...rek,
                ...tovartwo,
                ...tovarthree,
                ...tovarfour,
                ...tovarfive,
                ...tovarsix,
                ...tovarseven,
                ...tovareight,
                ...tovarnine,
                ...tovarten,
                ...tovareleven,
                ...tovartwelve,
                ...tovarthirteen,
                ...tovarFourteen,
            ]);
        }
    }, [podrobnee, rek, tovartwo, tovarthree, tovarfour, tovarfive, tovarsix, tovarseven, tovareight, tovarnine, tovarten, tovareleven, tovartwelve, tovarthirteen, tovarFourteen]);
    const keyword1 = 'MacBook';
    const keyword2 = 'macbook';

    const imagess = allItem.filter(element =>
        element.media_type === 'CAROUSEL_ALBUM' &&
        element.caption &&
        (element.caption.includes(keyword1) || element.caption.includes(keyword2))
    );

    return (
        <div className='products'>
            {imagess.map((item) => (
                <div key={item.id} className="products__form">
                    <div className="products__form-card">
                        <img className='products__form-photo' src={item.media_url} alt={item.caption} />
                        <button className='products__form-btn'>
                            <Link className='products__form-btn-link' to={`/Podrobnee/${item.id}`}>Подробнее</Link>
                        </button>
                    </div>
                </div>
            ))}

        </div>
    );
};

export default ModelMacBook;
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

function Rek() {



    const [rek, setRek] = useState([]);

    const sliceitem = rek.slice(0, 9)

    useEffect(() => {
        axios(`https://graph.instagram.com/v20.0/17841406139700705/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQWRQelRFel94dGNGY0U3WTNhNkFMSzJJTGNOUVZAfVlRIS2ZAKQkVuVC1SSGxSQUQxYzhoNWRzeGduQkJKTXVObmtORGZAVazBDeGxNUVgwRm9JcFRtMGdTSjdxd3ZAMTVdtQ0U5TGVmMGZA5ZAkU3OGRTMWZAHN2JjRzQZD&pretty=1&limit=25&after=QVFIUkdjQ1JVRE16ZAG5kVG91anV4ajlyb2U1X0NmOWJLQ3RYaHg3NUZA2VWZACbEJ6MnZAhZAkw1Um81V3ZATX1NINnZAjcFVIV0F6WkZA5YkhTQ1BnOWdqY2dOa0x3`)
            .then(({ data }) => setRek(data.data))
    }, [])
    
    if (!rek || rek.length === 0) return <p>Загрузка данных.</p>
    const images = sliceitem.filter(element => element.media_type === 'CAROUSEL_ALBUM');

    return (
        <div className='rek'>
            <h1 className='rek__title'>Рекомендуемые продукты</h1>
            <hr />
            <div className="rek__form">


                {images.map((item, idx) => {
                    return (
                        <div className="products__form">
                            <div className="products__form-card">
                                <img className='products__form-photo' src={item.media_url} />
                                <button className='products__form-btn'>
                                <Link className='products__form-btn-link' to={`/Podrobnee/${item.id}`}>Подробнее</Link>
                                </button>
                            </div>
                        </div>
                    )



                })}

            </div>


        </div>
    )
}

export default Rek
